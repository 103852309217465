const approvalMenu = [
    'mainmenu.mainmenu',
    'mainmenu.dashboard',
    'mainmenu.questions',
    'mainmenu.questions.questionReview',
    'mainmenu.questions.normalisation',
    'verification.verification',
    'verification.verificationBank',
    'verification.verificationDocument',
    'verification.verificationAttribute',
    'verification.verificationInterest',
    'account.account',
    'account.wallet',
    'account.fees'
]

export default approvalMenu;