const financeMenu = [
    'mainmenu.mainmenu',
    'mainmenu.dashboard',
    'actions.actions',
    'actions.payout',
    'verification.verification',
    'verification.verificationBank',
    'account.account',
    'account.wallet',
    'account.fees'
]

export default financeMenu;