const adminMenu = [
    'mainmenu.mainmenu',
    'mainmenu.dashboard',
    'mainmenu.subjects',
    'mainmenu.subjects.primary',
    'mainmenu.subjects.junior',
    'mainmenu.subjects.senior',
    'mainmenu.users',
    'mainmenu.users.guardians',
    'mainmenu.users.students',
    'operations.operations',
    'operations.officers',
    'operations.officers.officersManage',
    'operations.themes',
    'operations.themes.themesView',
    'account.account',
    'account.fees'
]

export default adminMenu